const validate = (values) => {
    const errors = {};
    // console.log(values);
    if(values.ownedLandArea<0){
        errors.ownedLandArea = "Value should be greater than zero";
    }

    if(values.leasedLandArea<0){
        errors.leasedLandArea = "Value should be greater than zero";
    }

    // if(values.ownedLandArea === '' && values.leasedLandArea === '' && values.leasedLandKhasraNumbers === '' && values.ownedLandKhasraNumbers===''
    //     && values.ownedDocuments.length ===0 && values.leasedDocuments.length ===0
    // ){
        
    // }

    // if(values.leasedLandKhasraNumbers===''){
    //     errors.leasedLandKhasraNumbers = "Leased Land Khasra Numbers is required";
    // }

    // if(values.leasedLandKhasraNumbers!='' && values.leasedDocuments.length ===0){
    //     errors.leasedDocuments = "Leased Land Documents are required";
    // }
    
    // if(values.ownedLandKhasraNumbers===''){
    //     errors.ownedLandKhasraNumbers = "Owned Land Khasra Numbers is required";
    // }
    
    // if(values.ownedLandKhasraNumbers!='' && values.ownedDocuments.length ===0){
    //     errors.ownedDocuments = "Owned Land Documents are required";
    // }
    
    // let n1 = values.ownedLandKhasraNumbers.length;
    // if(n1 > 0){
    //     const lastElement = values.ownedLandKhasraNumbers[n1 - 1];
    //     if(lastElement===""){
    //         //console.log("hit");
    //         errors.ownedLandKhasraNumbers = "Field can't be empty";
    //     }else{
    //         const subArray = values.ownedLandKhasraNumbers.slice(0, n1-1);
    //         //check for last element under subarray.
    //         if(subArray.includes(lastElement) === true){
    //             errors.ownedLandKhasraNumbers = "Entry exists."
    //         }
    //     }
        
    // }

    // let n2 = values.leasedLandKhasraNumbers.length;
    // if(n2 > 0){
    //     const lastElement = values.leasedLandKhasraNumbers[n2 - 1];
    //     if(lastElement === ''){
    //         errors.leasedLandKhasraNumbers = "Field can't be empty";
    //     }else{
    //         const subArray = values.leasedLandKhasraNumbers.slice(0, n2-1);
    //         //check for last element under subarray.
    //         if(subArray.includes(lastElement) === true){
    //             errors.leasedLandKhasraNumbers = "Entry exists."
    //         }
    //     }
        
    // }
    return errors;
}

export default validate;